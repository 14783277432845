/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpSecureUploadStates($stateProvider) {
  'ngInject';

  /* tslint:disable:object-literal-sort-keys */
  $stateProvider
    .state('secure-upload', {
      parent: 'public',
      url: '/secure-upload',
      // Add resolve data as attributes: <acp-secure-upload data="$resolve.fetchedData" ... />
      template: '<acp-secure-upload-layout />',
      data: {
        permissions: {
          only: 'password_unauthed',
          redirectTo: {
            password_unauthed: 'secure-upload-with-user-session'
          }
        }
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(/* webpackChunkName: "acp.section.secure-upload" */ './index').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('secure-upload-with-user-session', {
      parent: 'root',
      url: '/secure-upload',
      template: '<acp-secure-upload-layout-authed />',
      data: {
        permissions: {
          only: 'isCipConditionalAlertEligible',
          redirectTo: {
            isCipConditionalAlertEligible: 'secure-upload-unauth'
          }
        }
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(/* webpackChunkName: "acp.section.secure-upload" */ './index').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('secure-upload-unauth', {
      parent: 'public',
      url: '/secure-upload',
      // Add resolve data as attributes: <acp-secure-upload data="$resolve.fetchedData" ... />
      template: '<acp-secure-upload-layout />',
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(/* webpackChunkName: "acp.section.secure-upload" */ './index').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('secure-upload.select-file', {
      template: '<acp-upload-file></acp-upload-file>'
    })
    .state('secure-upload-unauth.select-file', {
      template: '<acp-upload-file></acp-upload-file>'
    })
    .state('secure-upload-with-user-session.select-file', {
      template: '<acp-upload-file></acp-upload-file>',
      data: {
        isAuthed: true
      }
    })
    .state('referral-code', {
      parent: 'public',
      url: '/secure-upload',
      template: '<acp-referral-code-component></acp-referral-code-component>'
    })
    .state('secure-upload-error', {
      parent: 'public',
      url: '/secure-upload-error',
      template: '<acp-auth-expired></acp-auth-expired>',
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(/* webpackChunkName: "acp.section.secure-upload" */ './index').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    });
}
